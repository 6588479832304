import React from "react"
import { clientManagementPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const ClienManagement = () => {
  return (
    <Layout>
      <Seo
        title="Automate Client Management and Simplify Accounting Practice"
        description={`Try Synkli to simplify your accounting practice with organised client data, dedicated file managers, and instant file sharing.`}
      />

      <FeatureDetailsPage data={clientManagementPage} />
      
    </Layout>
  )
}

export default ClienManagement
